.App {
    text-align: center;
}

*::-webkit-scrollbar {
    width: 13px;
}

*::-webkit-scrollbar-track {
    background: none; 
}

*::-webkit-scrollbar-thumb {
    background-color: rgb(22, 25, 29); 
    border: 4px solid transparent;
    border-radius: 8px;
    background-clip: padding-box;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255, 255, 255, 0.4); 
}